import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Header from '../../components/Header';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';

const CountryExcursionDetail = () => {
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const token = localStorage.getItem('token');
  const [loading, setLoading] = useState(false);

  const [details, setDetails] = useState({
    CountryID: '',
    CountryName: '',
    departureDate: '',
    excursions: '',
  });

  const [excursions, setExcursions] = useState([]);

  const getDetails = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/country-excursion/${id}`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setDetails(data?.data);
      console.log(data.data);
    }
  };

  const getExcursions = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/countryExcursions/${details.CountryID}`, {
      headers: { token },
    });
    const data = await res.json();
    setExcursions(data.excursions);
    console.log(data.excursions);
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    if (details.CountryID) {
      getExcursions();
    }
  }, [details.CountryID]);

  const handleChange = (e) => {
    setDetails({ ...details, [e.target.name]: e.target.value });
  };

  const handleChecked = (e, excursionId) => {
    const newExcursions = e.target.checked
      ? [...details.excursions, excursionId]
      : details.excursions.filter((id) => id !== excursionId);
    setDetails({ ...details, excursions: newExcursions });
  };

  const handlevalidation = () => {
    const { CountryID, departureDate, excursions } = details;
    if (!excursions || excursions.length === 0) {
      setOpenToast(true);
      setToastmessage('Atleast one excursion should be selected');
      return false;
    }
    return true;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (handlevalidation()) {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/country-excursion/${id}`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          token,
        },
        body: JSON.stringify(details),
      });
      const data = await res.json();
      if (data.message) {
        setOpenToast(true);
        setToastmessage(data.message);
        if (data.success) {
          setTimeout(() => {
            navigate('/country-excursion');
          }, 2000);
        }
      }
    }
    setLoading(false);
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="DESTINATION EXCURSION DETAILS" subtitle="Destination Excursion Details" />

        <Box>
          <Link to={-1}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Go Back
            </Button>
          </Link>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '1.25rem',
            paddingBottom: '20px',
          }}
        >
          {/* Country */}
          {/* <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Destination"
            onChange={(e) => {
              setDetails({ ...details, countryId: e.target.value, excursions: [] });
            }}
            value={details.countryId}
            name="countryId"
            select
            required
          >
            {countryNames &&
              countryNames.map((country) => (
                <MenuItem key={country.CountryID} value={country.CountryID}>
                  {country.CountryName}
                </MenuItem>
              ))}
          </TextField> */}
          <TextField
            type="text"
            fullWidth
            variant="filled"
            label="Destination"
            onChange={handleChange}
            value={details.CountryName}
            name="CountryName"
            InputLabelProps={{ shrink: true, style: { color: colors.grey[300] } }}
            style={{ colorScheme: 'dark' }}
            disabled
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#fff',
              },
            }}
          />

          {/* Departure Date */}
          <TextField
            type="text"
            fullWidth
            variant="filled"
            label="Departure Date"
            value={details.departureDate}
            InputLabelProps={{ shrink: true, style: { color: colors.grey[300] } }}
            style={{ colorScheme: 'dark' }}
            disabled
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#fff',
              },
            }}
          />

          {/* Excursions */}
          <Box
            style={{
              display: 'grid',
              gap: '10px',
              padding: '15px',
              background: colors.primary[400],
              cursor: 'pointer',
              borderRadius: '8px',
            }}
          >
            <Typography variant="h5" fontSize="18px" fontWeight="bold">
              Excursions
            </Typography>
            <div style={{ display: 'grid' }}>
              {excursions?.length > 0 ? (
                excursions.map((excursion) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={details.excursions.includes(excursion.ExcursionID)}
                        onChange={(e) => handleChecked(e, excursion.ExcursionID)}
                        color="success"
                      />
                    }
                    label={excursion.ExcursionName}
                    key={excursion.ExcursionID}
                  />
                ))
              ) : (
                <i>Please choose a Destination.</i>
              )}
            </div>
          </Box>

          <Button
            type="submit"
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              width: 'fit-content',
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 30px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
            disabled={loading}
          >
            {loading ? (
              <>
                <CircularProgress color="secondary" size="20px" sx={{ marginRight: '10px' }} />
                Updating
              </>
            ) : (
              'Update'
            )}
          </Button>
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default CountryExcursionDetail;
