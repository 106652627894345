import { Box, Button, IconButton, Snackbar, TextField, Typography } from '@mui/material';
import Header from '../../components/Header';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@emotion/react';
import { tokens } from '../../theme';
import EditIcon from '@mui/icons-material/Edit';

const PrivacyPolicy = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const token = localStorage.getItem('token');
  const [edit, setEdit] = useState(false);
  const [values, setValues] = useState({
    content: '',
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const getData = async () => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/privacypolicy`, { headers: { token } });
    const data = await res.json();
    if (data.success) {
      setValues(data.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!values.content) {
      setOpenToast(true);
      setToastmessage('Please fill the details');
      return;
    }

    try {
      const res = await fetch(`${process.env.REACT_APP_APILINK}/admin/privacypolicy`, {
        method: 'PUT',
        headers: {
          'Content-type': 'application/json',
          token,
        },
        body: JSON.stringify(values),
      });
      const data = await res.json();
      if (data.message) {
        setOpenToast(true);
        setToastmessage(data.message);
        if (data.success) {
          setTimeout(() => {
            getData();
          }, 2000);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" rowGap="10px" flexWrap="wrap-reverse">
        <Header title="PRIVACY POLICY" subtitle="Company Privacy Policy" />

        <Box>
          <Button
            onClick={() => setEdit(!edit)}
            startIcon={<EditIcon />}
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: '14px',
              fontWeight: 'bold',
              padding: '10px 20px',
              '&:hover': {
                backgroundColor: colors.blueAccent[800],
              },
            }}
          >
            Edit
          </Button>
        </Box>
      </Box>

      <form onSubmit={handleFormSubmit}>
        <Box
          style={{
            display: 'grid',
            gap: '10px',
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            multiline
            rows={20}
            label="Privacy Policy"
            onChange={handleChange}
            name="content"
            value={values.content}
            required
            disabled={!edit}
            sx={{
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#fff',
              },
            }}
          />

          {edit && (
            <Button
              type="submit"
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                width: 'fit-content',
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 30px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              Update
            </Button>
          )}
        </Box>
      </form>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default PrivacyPolicy;
