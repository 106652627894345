import { Box, Button, CircularProgress, IconButton, Snackbar, useTheme } from '@mui/material';
import {
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { tokens } from '../../theme';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import Header from '../../components/Header';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      {/* <GridToolbarExport /> */}
    </GridToolbarContainer>
  );
}

const AllPassengers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastmessage] = useState('');
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [sortModel, setSortModel] = useState([{ field: 'id', sort: 'asc' }]);
  const [filterModel, setFilterModel] = useState({
    items: [
      { columnField: 'name', operatorValue: 'contains', value: '' },
      { columnField: 'email', operatorValue: 'contains', value: '' },
    ],
  });

  const token = localStorage.getItem('token');

  const getData = async () => {
    setLoading(true);

    // Prepare query parameters based on state
    const sortField = sortModel[0]?.field || 'id';
    const sortOrder = sortModel[0]?.sort || 'asc';
    const filterValue = filterModel.items.reduce((acc, item) => {
      acc[item.columnField] = item.value;
      return acc;
    }, {});

    const res = await fetch(
      `${
        process.env.REACT_APP_APILINK
      }/getallpassengers?page=${page}&limit=${pageSize}&sortBy=${sortField}&sortOrder=${sortOrder}&filter=${JSON.stringify(
        filterValue
      )}`,
      { headers: { token } }
    );

    const data = await res.json();
    if (data.success) {
      setAllUsers(data.passengers);
      setPage(data?.pagination?.page);
      setPageSize(data?.pagination?.limit);
      setTotalCount(data?.pagination?.total);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [page, pageSize, sortModel, filterModel]);

  // Delete Passenger
  const handleDelete = async (id) => {
    const res = await fetch(`${process.env.REACT_APP_APILINK}/passengers/${id}`, {
      headers: { token },
      method: 'DELETE',
    });
    const data = await res.json();
    if (data.message) {
      setOpenToast(true);
      setToastmessage(data.message);
      if (data.success) {
        getData();
      }
    }
  };

  const columns = [
    { field: 'id', headerName: 'Id', hide: true, filterable: false, sortable: false },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 100,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'surname',
      headerName: 'Surname',
      flex: 1,
      minWidth: 100,
      cellClassName: 'name-column--cell',
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'View',
      filterable: false,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Link to={`/passenger/${cellValues.row.id}`}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[500],
                color: colors.grey[100],
                ':hover': { backgroundColor: colors.blueAccent[600] },
              }}
              variant="contained"
            >
              <VisibilityIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: 'Edit',
      filterable: false,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Link to={`/passenger/${cellValues.row.id}/editpassenger`}>
            <Button
              sx={{
                backgroundColor: colors.greenAccent[600],
                color: colors.grey[100],
                ':hover': { backgroundColor: colors.greenAccent[700] },
              }}
              variant="contained"
            >
              <EditIcon />
            </Button>
          </Link>
        );
      },
    },
    {
      field: 'Delete',
      filterable: false,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <Button
            sx={{
              backgroundColor: colors.redAccent[500],
              color: colors.grey[100],
              ':hover': { backgroundColor: colors.redAccent[600] },
            }}
            variant="contained"
            onClick={() => handleDelete(cellValues.row.id)}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  // Toast
  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const action = (
    <>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseToast}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
    setPage(1); // Reset to the first page when sorting changes
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    setPage(1); // Reset to the first page when filter changes
  };

  // if (loading) {
  //   return (
  //     <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80vh' }}>
  //       <CircularProgress color="secondary" />
  //     </Box>
  //   );
  // }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" gap="10px" flexWrap="wrap">
        <Header title="PASSENGERS" subtitle="Managing all Passengers" />

        <Box display="flex" gap="10px" flexWrap="wrap">
          <Link to={'/addpassengers'}>
            <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: '14px',
                fontWeight: 'bold',
                padding: '10px 20px',
                '&:hover': {
                  backgroundColor: colors.blueAccent[800],
                },
              }}
            >
              <PersonOutlinedIcon sx={{ mr: '10px' }} />
              Add Passenger
            </Button>
          </Link>
        </Box>
      </Box>

      <Box
        m="10px 0 0 0"
        height="667px"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
          '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
            color: `${colors.grey[100]} !important`,
            fontSize: 14,
          },
        }}
      >
        <DataGrid
          rows={allUsers}
          columns={columns}
          getRowId={(row) => row.id}
          components={{ Toolbar: CustomToolbar }}
          autoPageSize
          page={page - 1}
          pageSize={pageSize}
          sortingOrder={['asc', 'desc']}
          sortModel={sortModel}
          onSortModelChange={handleSortModelChange}
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          onPageChange={(newPage) => setPage(newPage + 1)} // DataGrid uses 0-based index for pages
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)} // Handle page size change
          paginationMode="server" // Enable server-side pagination
          rowCount={totalCount} // Total number of rows (data.pagination.total)
          loading={loading}
        />
      </Box>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        onClose={handleCloseToast}
        message={toastMessage}
        action={action}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      />
    </Box>
  );
};

export default AllPassengers;
